import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import { PATHS } from 'common/routes/PATHS';

interface Page {
  Icon?: typeof SvgIcon;
  href: string;
  key: keyof typeof PATHS;
}

export const PAGES: Page[] = [
  {
    href: '/tools',
    key: 'Tools'
  },
  {
    href: '/tools/video-trimmer',
    key: 'VideoTrimmer'
  }
];
