'use client';

import * as React from 'react';
import { FC } from 'react';

import { XAppBar } from 'common/components/XAppBar/XAppBar';
import { createEditorModel, EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';
import { EditorTool } from 'common/pages/EditorPage/models/types';
import { PageProps } from 'common/pages/PageProps';

import { Canvas } from './Canvas/Canvas';
import { EditorLayout } from './EditorLayout/EditorLayout';
import { Timeline } from './Timeline/Timeline';
import { ToolsSettings } from './ToolsSettings/ToolsSettings';

interface Props extends PageProps {
  readonly toolsLabels?: Record<EditorTool, string>;
}

export const Editor: FC<Props> = ({ lang, toolsLabels }) => (
  <EditorModelContext.Provider value={createEditorModel({ toolsLabels })}>
    <EditorLayout header={<XAppBar lang={lang} />} timeline={<Timeline />} toolsSettings={<ToolsSettings />}>
      <Canvas />
    </EditorLayout>
  </EditorModelContext.Provider>
);
