import * as React from 'react';
import { FC } from 'react';

import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { LogoSvg } from 'common/components/Icons/LogoSvg';
import { getGlobalTranslate } from 'common/i18n/globalTranslate';
import { Locale } from 'common/i18n/i18n.config';
import { Tools, ToolsVideoTrimmer } from 'common/routes';
import { BORDER } from 'common/styles/colors';

import { MobileMenu } from './MobileMenu';

interface Props {
  readonly lang: Locale;
}

export const XAppBar: FC<Props> = async ({ lang }) => {
  const dictionary = await getGlobalTranslate(lang);
  const { Actions, Navigation, Videmix } = dictionary;

  return (
    <AppBar color="inherit" position="fixed" sx={{ borderBottom: BORDER, boxShadow: 'none', zIndex: 10000 }}>
      <Container component="nav" maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'flex', xs: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h6"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Box sx={{ display: { md: 'none', xs: 'flex' }, flexGrow: 1 }}>
            <MobileMenu dictionary={dictionary} />
          </Box>

          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'none', xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h5"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1 }}>
            <Box sx={{ alignItems: 'center', display: 'flex', m: 2 }}>
              <Typography variant="body1">
                <Tools.Link lang={lang} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {Navigation.Tools}
                </Tools.Link>
              </Typography>
            </Box>
            <Box sx={{ alignItems: 'center', color: 'inherit', display: 'flex', m: 2 }}>
              <Typography variant="body1">
                <ToolsVideoTrimmer.Link lang={lang} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {Navigation.VideoTrimmer}
                </ToolsVideoTrimmer.Link>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 0 }}>
            <Button sx={{ mr: 1 }} variant="outlined">
              {Actions.LogIn}
            </Button>
            <Button color="primary" variant="contained">
              {Actions.SignUp}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
